






































































































































































import { Store, TAwait } from '@/store/store';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator'
import GenericForm from '@/components/forms/GenericForm.vue'
import NumberTracker from '@/components/misc/NumberTracker.vue'
import {Login as LoginRoute} from '@/router'
@Component({
  components:{
    GenericForm,
    NumberTracker
  },
  metaInfo: {
    title: 'Reseller Registration',
  }
})
export default class ResellerRegistration extends Store {
  componentTitle = "Reseller Registration"

  codeIsValid = false

  registeredEmail: TAwait<ReturnType<typeof Store.prototype.resellerRegistrationCodeValidation>> | void = {
    email: '',
  }

  creds: Parameters<typeof Store.prototype.resellerRegistrationStepTwo>[0]['creds'] = {
    email:{
      value: '',
      errors: []
    },
    password:{
      value: '',
      errors: []
    },
    password_confirmation:{
      value: '',
      errors: []
    },
  }

  businessProfile: Parameters<typeof Store.prototype.resellerRegistrationStepOne>[0]['profile'] = {
    email:{
      value: '',
      errors: []
    },
    business_name:{
      value: '',
      errors: []
    },
    lead_contact:{
      value: '',
      errors: []
    },
    address_line_1:{
      value: '',
      errors: []
    },
    town:{
      value: '',
      errors: []
    },
    county_state:{
      value: '',
      errors: []
    },
    postcode:{
      value: '',
      errors: []
    },
    country:{
      value: '',
      errors: []
    },
    web_address:{
      value: '',
      errors: []
    },
    address_line_2:{
      value: '',
      errors: []
    },
  }

  steps = [
    {
      number: 0,
      title: 'Business Profile'
    },
    {
      number: 1,
      title: 'Credentials'
    }
  ]

  currentStep = this.steps[0].number

  @Watch('steps', {deep: true})
  async mounted(){
    await this.refresh()
  }

  async refresh(){
    this.registeredEmail = await this.resellerRegistrationCodeValidation(this.code)
    .catch(() => {
      this.$toast.error("Something Went Wrong")
    })

    if(this.registeredEmail && this.registeredEmail.email) {
      this.codeIsValid = true
      this.creds.email.value = this.registeredEmail.email
    }
  }

  async saveCredentials(){
    this.clearFormErrors(this.creds)
    this.resellerRegistrationStepTwo({
      creds: this.creds,
      code: this.code,
    }).then(() => {
      this.$toast.success("Your Reseller Registration has been completed. You may now login")
      this.$router.push(LoginRoute)
    }).catch(({errors}) => {
      this.$toast.error("There Was An Error Trying To Save These Details")
      Object.keys(errors).map((i: string) => {
        //@ts-ignore
        this.creds[i].errors = errors[i];
      });
    })
  }

  async saveProfile(){
    this.clearFormErrors(this.businessProfile)
    this.resellerRegistrationStepOne({
      profile: this.businessProfile,
      code: this.code,
    }).then(() => {
      this.$toast.success("Your Reseller Registration details has been saved")
      this.nextStep()
    }).catch(({errors}) => {
      this.$toast.error("There Was An Error Trying To Save These Details")
      Object.keys(errors).map((i: string) => {
        //@ts-ignore
        this.businessProfile[i].errors = errors[i];
      });
    })
  }

  nextStep(){
    ++this.currentStep
  }

  prevStep(){
    --this.currentStep
  }

  transistionToStep(id:string){
    // this.currentStep = id
  }

  get code(){
    return this.$route.params.code
  }

  clearFormErrors(obj: typeof ResellerRegistration.prototype.creds | typeof ResellerRegistration.prototype.businessProfile){
    for (const i in obj) {
      // @ts-ignore
      obj[i].errors = [];
    }
  }
}
